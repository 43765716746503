import BGImageSmall from '../../assets/images/bg-sp.jpg'
import BGImage from '../../assets/images/bg.jpg'
import Logo from '../../assets/images/logo.png'
import GoToTop from '../../assets/images/link-top.png'

export const TochigiLayout: React.FC<any> = ({ children }) => {
  // const RightSVG = () => (
  //   <svg
  //     xmlns="http://www.w3.org/2000/svg"
  //     className="h-7 w-7 text-white absolute right-2"
  //     viewBox="0 0 20 20"
  //     fill="currentColor"
  //   >
  //     <path
  //       fillRule="evenodd"
  //       d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z"
  //       clipRule="evenodd"
  //     />
  //   </svg>
  // )

  let menu = (
    <>
      <nav className="nav-global">
        <a href="https://www.tochigi-rising-kids.jp/news/" className="link-news"><img src="https://www.tochigi-rising-kids.jp/wp-content/themes/raijing/imgs/common/text-news.webp" alt="お知らせ・新着情報"/></a>
        <a href="https://www.tochigi-rising-kids.jp/tairyoku/" className="link-tairyoku"><img src="https://www.tochigi-rising-kids.jp/wp-content/themes/raijing/imgs/common/text-tairyoku.webp" alt="栃木県児童生徒の体力ゾーン"/></a>
        <a href="https://www.tochigi-rising-kids.jp/shindan/" className="link-shindan"><img src="https://www.tochigi-rising-kids.jp/wp-content/themes/raijing/imgs/common/text-shindan.webp" alt="体力診断ゾーン"/></a>
        <a href="https://www.tochigi-rising-kids.jp/movies/" className="link-movies"><img src="https://www.tochigi-rising-kids.jp/wp-content/themes/raijing/imgs/common/text-movies.webp" alt="体力アップ動画ゾーン"/></a>
        <a href="https://www.tochigi-rising-kids.jp/exercise/" className="link-exercise"><img src="https://www.tochigi-rising-kids.jp/wp-content/themes/raijing/imgs/common/text-exercise.webp" alt="運動遊びゾーン"/></a>
        <a href="https://www.tochigi-rising-kids.jp/challenge/" className="link-challenge"><img src="https://www.tochigi-rising-kids.jp/wp-content/themes/raijing/imgs/common/text-challenge.webp" alt="チャレンジランキングゾーン"/></a>
        <a href="https://www.tochigi-rising-kids.jp/genkikids/" className="link-genkikids"><img src="https://www.tochigi-rising-kids.jp/wp-content/themes/raijing/imgs/common/text-genkikids.webp" alt="とちぎ元気キッズゾーン"/></a>
        <a href="https://www.tochigi-rising-kids.jp/link/" className="link-link"><img src="https://www.tochigi-rising-kids.jp/wp-content/themes/raijing/imgs/common/text-link.webp" alt="その他・関連リンク等"/></a>
        <a href="https://www.tochigi-rising-kids.jp/about/" className="link-about"><img src="https://www.tochigi-rising-kids.jp/wp-content/themes/raijing/imgs/home/link-about.png" alt="とちぎっ子雷ジングひろばとは"/></a>
      </nav>
    </>
  )

  return (
    <div>
      <img
        className="hidden sm:block object-cover h-full w-full mx-auto absolute z-0"
        src={BGImage}
        alt=""
      />
      <img
        className="block sm:hidden object-cover h-full w-full mx-auto absolute z-0"
        src={BGImageSmall}
        alt=""
      />
      <div className="h-screen relative overflow-y-scroll">
        <div className="flex flex-col justify-between min-h-screen w-full container mx-auto relative z-10 py-10">
          <div>
            <div className="flex flex-col items-center space-y-5 sm:space-y-10">
              <div className="logo-drop-shadow">
                <a href="https://www.tochigi-rising-kids.jp" target="_self">
                  <img
                    className="w-72 sm:w-96 mx-auto z-10"
                    src={Logo}
                    alt=""
                  />
                </a>
              </div>

              <div className="hidden md:block w-full px-5 sm:px-4">{menu}</div>

              <div className="w-full bg-white-transparent rounded-lg max-w-4xl lg:max-w-full mx-auto px-2 sm:px-8 py-2 sm:py-12 shadow-lg border border-blue-100 overflow-x-auto">
                {children}
              </div>
            </div>

            <div className="block md:hidden w-full px-5 sm:px-4 mt-10">
              {menu}
            </div>

            <div className="z-10 flex justify-center sm:justify-end mr-0 sm:mr-10 mt-10">
              <a href="https://www.tochigi-rising-kids.jp/">
                <img className="w-24 sm:w-36" src={GoToTop} alt="" />
              </a>
            </div>
          </div>

          <div className="mt-10">
            <div className="flex flex-col items-center mx-auto">
              <div className="flex flex-col md:flex-row  md:space-x-10 space-y-4 md:space-y-0">
                <a
                  className="bg-green-500 hover:bg-white rounded-full w-60 text-center tracking-widest text-white text-base py-1 hover:text-green-500"
                  href="https://www.tochigi-rising-kids.jp/contact/"
                >
                  お問い合わせ
                </a>
                <a
                  className="bg-green-500 hover:bg-white rounded-full w-60 text-center tracking-widest text-white text-base py-1 hover:text-green-500"
                  href="https://www.tochigi-rising-kids.jp/#"
                >
                  ご利用について
                </a>
                <a
                  className="bg-green-500 hover:bg-white rounded-full w-60 text-center tracking-widest text-white text-base py-1 hover:text-green-500"
                  href="https://www.tochigi-rising-kids.jp/privacy/"
                >
                  個人情報の取り扱いについて
                </a>
              </div>
            </div>
            <div className="mt-4 text-center mb-8 mx-auto">
              <span className="text-sm text-white tracking-widest">
                ©栃木県教育委員会事務局スポーツ振興課
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
